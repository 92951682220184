import { RootObject } from '@/model/rootObject'
import { SpreadLink } from '@/model/spreadOrder'
import https from '@/utils/https'
import { ContentType, Method, RequestParams } from 'axios-mapper'
import storage, { StorageType } from '@/utils/storage'

const getSignData = () => {
  let signData = storage.rcGetItem(StorageType.local, 'signData')
  signData = signData ? JSON.parse(signData) : ''
  return signData
}

export const requestLink = () => {
  return https().request<RootObject<SpreadLink<SpreadLink<string | number>>>>('agent/share/link', Method.POST, getSignData(), ContentType.json)
}

// 列表形式的推广链接
export const requestLinkList = () => {
  return https().request<RootObject<SpreadLink<SpreadLink<string | number>>>>('agent/share/linkList', Method.POST, getSignData(), ContentType.json)
}

export const requestStatus = (params: RequestParams) => {
  return https().request<RootObject<SpreadLink<SpreadLink<string | number>>>>('agent//share/status', Method.POST, params, ContentType.json)
}
export const requestEdit = (params: RequestParams) => {
  return https().request<RootObject<SpreadLink<SpreadLink<string | number>>>>('agent//share/edit', Method.POST, params, ContentType.json)
}

export const requestUpload = (params: RequestParams) => {
  return https().request<RootObject<SpreadLink<SpreadLink<string | number>>>>('agent/file/upload', Method.POST, params, ContentType.json)
}
