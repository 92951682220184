
import { defineComponent, reactive, toRefs, watch } from 'vue'
import { requestStatus, requestEdit } from '@/apis/spread'
import { ElMessage, ElMessageBox } from 'element-plus'
import { SpreadLink } from '@/model/spreadOrder'
// import requestMiddle, { RequestInter } from '@/utils/request'
interface LinkData{
    handleSwitch: (row: Row) => void
    handleEdit: (row: Row) => void
    dialogVisible: Boolean
    handleEditPrice: (id: Number, val: String) => void
    mergeArray: number[]
    pos: number
    currentData: Array<SpreadLink<SpreadLink<string | number>>>

}
interface Row{
  id: Number
  status: Boolean
  sell_price: Number
  share_link: string
  product_name: string
  cost_price: number
}
export default defineComponent({
  props: {
    data: {
      type: Array,
      default() {
        return []
      }
    },
    isSecondary: {
      type: Boolean,
      default: false
    }
  },
  emits: ['reFresh'],
  setup(props: any, ctx) {
    const data: LinkData = reactive({
      dialogVisible: false,
      mergeArray: [],
      currentData: [],
      pos: 0,
      handleEdit(row: Row) {
        (this as any).$router.push({ path: '/spread/edit', query: { id: row && row.id } })
      },
      handleSwitch: (row: Row) => {
        // 上架状态变下架就弹出提示
        row?.status && ElMessageBox.alert('链接已下架!')
        requestStatus({
          id: row && row.id, status: row && !row.status
        }).then((res) => {
          if (res?.code === 200) {
            row && (row.status = !row.status)
          } else {
            ElMessage.warning(res?.msg)
          }
        })
      },
      openEditPrice(row: Row) {
        console.log(row.cost_price)
        const id = row && row.id
        ElMessageBox.prompt('修改价格', '提示', {
          confirmButtonText: '保存',
          cancelButtonText: '取消',
          inputValue: String(row && row.sell_price),
          inputValidator: (val: string) => {
            const patten = /^[+-]?(0|([1-9]\d*))(\.\d{0,2})?$/g
            return patten.test(val) && (Number(val) >= row.cost_price)
          },
          inputErrorMessage: '售价不能低于成本价'
        }).then(({ value }: {value: string}) => {
          data.handleEditPrice(id, value)
        })
      },
      handleEditPrice: async(id, value) => {
        await requestEdit({
          id: id,
          sell_price: value
        }).then((res) => {
          if (res?.code === 200) {
            ElMessage.success('修改成功')
            ctx.emit('reFresh')
          } else {
            ElMessage.error(res?.msg)
          }
        }, (error) => {
          ElMessage.error(error)
        })
      },
      mergeSameRow({ rowIndex, columnIndex }: {row: any, column: any, rowIndex: number, columnIndex: number}) {
        if (columnIndex === 0 || columnIndex === 1) { // 表格第一列和二列
          if (rowIndex === 0) {
            return {
              rowspan: 3,
              colspan: 1
            }
          } else {
            return {
              rowspan: 0,
              colspan: 0
            }
          }
        }
      }
    })
    watch(() => props.data, (now) => {
      data.currentData = now
      const list: Array<SpreadLink<SpreadLink<string | number>>> = props.data
      for (let i = 0; i < list.length; i++) {
        if (i === 0) {
          data.mergeArray.push(1)
          data.pos = 0
        } else {
          // 判断当前元素与上一个元素是否相同
          // 根据相同id进行合并,根据需要可进行修改
          if (list[i].share_link === list[i - 1].share_link) {
            data.mergeArray[data.pos] += 1
            data.mergeArray.push(0)
          } else {
            data.mergeArray.push(1)
            data.pos = i
          }
        }
      }
    })
    return { ...toRefs(data) }
  }
})
