<template>
  <div class="fami-container">
    <template v-if="!isLoading">
      <div class="agent-info">
        <div class="avatar-group">
          <img
            src="@/assets/images/avatar.png"
            alt="avatar"
          >
          <div class="avatar-edit">
            <div>
              <span class="name">{{ info.agent_name }}
              </span>
              <el-popover
                placement="bottom"
                :width="280"
                trigger="click"
                v-if="!isSecondary && popCanShow"
              >
                <template #reference>
                  <el-button
                    type="text"
                    ref="avarImg"
                  >
                    <i
                      class="el-icon-edit edit-icon"
                    />
                  </el-button>
                </template>
                <div class="edit-info">
                  <p class="title">
                    修改信息
                  </p>
                  <InfoEditForm
                    :initData="formData"
                    @validSuccess="validSuccess"
                    @handleReset="handleReset"
                  />
                </div>
              </el-popover>
            </div>
            <!-- <div
              class="freeze"
            >
              状态：正常
              <el-button
                class="freeze-btn"
                @click="handleFreeze"
                size="mini"
                v-if="!isSecondary"
              >
                冻结账户
              </el-button>
            </div> -->
          </div>
        </div>
        <p>手机号：{{ info.mobile }}</p>
        <p>加入时间：{{ info.create_time }}</p>
      </div>
      <div class="panel-item">
        <h2><span>业绩概况</span></h2>
        <div class="cont">
          <div class="item">
            <p>累计销售额（元）</p>
            <div class="show">
              <img
                src="@/assets/images/dashboard/sellIcon.svg"
                alt="sellIcon"
                class="icon"
              >
              <CountTo
                :start-val="0"
                :end-val="achieve.sales_volume"
                :duration="2600"
                :decimals="decimals"
              />
            </div>
          </div>
          <div class="item">
            <p>累计佣金（元）</p>
            <div class="show">
              <svg
                class="icon"
                aria-hidden="true"
                style="color:#DE5D6F"
              >
                <use xlink:href="#iconrmb" />
              </svg>
              <CountTo
                :start-val="0"
                :end-val="achieve.commission_amount"
                :duration="2600"
                :decimals="decimals"
              />
            </div>
          </div>
          <div
            class="item"
          >
            <router-link :to="toOrder">
              <p>累计订单</p>
              <div class="show">
                <svg
                  class="icon"
                  aria-hidden="true"
                  style="color:#59A2F1"
                >
                  <use xlink:href="#iconmessage" />
                </svg>
                <CountTo
                  :start-val="0"
                  :end-val="achieve.order_num"
                  :duration="2600"
                  :decimals="decimals"
                />
              </div>
            </router-link>
          </div>
        </div>
      </div>
      <div class="panel-item">
        <h2 class="mb20">
          <span>推广链接</span>
        </h2>
        <component
          :is="currentType"
          :data="linkInfo"
          @reFresh="requestInfo"
          :isSecondary="isSecondary"
        />
      </div>
    </template>
  </div>
</template>

<script lang='ts'>
import { defineComponent, reactive, toRefs, onBeforeMount, ref, nextTick } from 'vue'
import DistributorTable from '../spread/components/DistributorTable.vue'
import DivideTable from '../spread/components/Table.vue'
import requestMiddle, { RequestInter } from '@/utils/request'
import { requestSecondInfo, requestSeconEdit } from '@/apis/second'
import { CountTo } from 'vue3-count-to'
import { ElMessage, ElMessageBox } from 'element-plus'
import { useRoute } from 'vue-router'
import { IndexMessage, AgentType } from '@/model/orderModel'
import InfoEditForm, { FormData } from './components/InfoEditForm.vue'
export default defineComponent({
  components: {
    DistributorTable,
    DivideTable,
    CountTo,
    InfoEditForm
  },
  setup() {
    const route = useRoute()
    const avarImg = ref(null)
    const currentType = ref('distributor-table')
    const editInfo = reactive({
      formData: {
        id: '',
        agent_name: '',
        mobile: '',
        password: ''
      },
      editVisible: true
    })
    const state = reactive({
      linkInfo: [],
      achieve: {},
      info: {},
      isLoading: true,
      isSecondary: true,
      popCanShow: true,
      toOrder: '/order',
      requestInfo: async() => {
        const option = { agent_id: editInfo.formData.id }
        const data = await requestMiddle({
          requestMethodName: requestSecondInfo,
          option
        } as RequestInter)
        try {
          const { achievement_data, info, share_list } = data
          state.achieve = achievement_data
          state.info = info
          if (info.agent_type === AgentType.DIVIDER) { // 分成代理
            currentType.value = 'divide-table'
          }
          state.linkInfo = share_list.map((item: IndexMessage<string | number>, index: number) => {
            item.serial = index + 1
            return item
          })
          editInfo.formData.id = info.id
          const { agent_name, mobile } = info
          editInfo.formData.agent_name = agent_name
          editInfo.formData.mobile = mobile
        } catch (error) {
          console.log(error)
        }
        state.isLoading = false
      }
    })

    const formMethod = reactive({
      validSuccess: async(option: FormData, cb: () => void) => {
        await requestSeconEdit(option).then((res) => {
          if (res?.code === 200) {
            state.popCanShow = false
            nextTick(() => {
              state.popCanShow = true
            })
            ElMessage.success('修改成功')
            state.requestInfo()
          } else {
            ElMessage.error(res?.msg)
          }
        })
        cb()
      },
      handleReset: () => {
        state.popCanShow = false
        nextTick(() => {
          state.popCanShow = true
        })
      }
    })

    const freeze = reactive({
      confText: '二级代理冻结后，推广链接失效，后续订单会统计到主账户下。确认冻结吗？',
      handleFreeze: () => {
        ElMessageBox.confirm(freeze.confText, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          console.log(2)
        }).catch(() => {
          console.log('放弃修改')
        })
      }
    })

    onBeforeMount(() => {
      if (route.name === 'Basicinfo') { // 一级代理系统
        const id = route.params.id as string
        state.isSecondary = false
        editInfo.formData.id = id
        state.toOrder = `/order/secondaryList/${id}`
      }
      state.requestInfo()
    })
    return { ...toRefs(state), ...toRefs(editInfo), ...toRefs(formMethod), avarImg, currentType, ...toRefs(freeze) }
  }
})
</script>

<style lang="scss" scoped>
.agent-info{
  display: flex;
  align-items: center;
  font-size: 22px;
  color: #9AA9C0;
  position: relative;
  .edit-icon{
    color: #1989FA;
    margin-left: 12px;
    font-size: 20px;
  }
  .avatar-group{
    display: flex;
    align-items: center;
    .avatar-edit{
      display: flex;
      align-items: center;
      position: relative;
    }
    .name{
      font-size: 22px;
      font-weight: 400;
      color: #000;
      margin-left: 30px;
    }
  }
  p{
      margin-left: 100px;
    }
  .freeze{
    position: absolute;
    top: 40px;
    left: 30px;
    display: flex;
    font-size: 16px;
    width: 120%;
    align-items: center;
    .freeze-btn{
      margin-left: 10px;
    }
  }
}
.edit-info .title{
  text-align: center;
  margin: 20px 0;
  font-size: 14px;
  color: #303133;
}

.panel-item {
  margin-top: 30px;
  h2{
    color: #51AA8D;
    height: 40px;
    line-height: 40px;
    background: rgba(190, 190, 190, 0.1);
    font-size: 0;;
    padding-left: 8px;
    &.mb20{
      margin-bottom: 20px;
    }
    span{
      vertical-align: middle;
      font-size: 22px;
    }
    &::before{
      content:"";
      display: inline-block;
      width: 6px;
      height: 28px;
      background: #51AA8D;
      border-radius: 4px;
      vertical-align: middle;
      margin-right: 10px;
    }
  }
  .cont{
    margin-top: 50px;
    display: flex;
    .item{
      margin: 0 168px 0 20px;
      p{
        color: #999;
        font-size: 22px;
      }
      .show{
        color: #6FC6C5;
        font-size: 32px;
        font-weight: bold;
        line-height: 1.5;
        margin-top: 26px;
        .icon{
          font-size: 44px;
          margin-right: 25px;
        }
      }
      &:nth-of-type(2) .show{
        color: #DE5D6F;
      }
      &:nth-of-type(3) .show{
        color: #59A2F1;
      }
    }
  }
}
@media screen and (max-width: 1200px) {
  .agent-info{
    p{
      margin-left: 40px;
    }
  }
  .panel-item{
    .cont{
      .item{
        margin: 0 70px 0 20px;
      }
    }
  }
}
@media screen and (max-width: 969px) {
  .agent-info{
    flex-direction: column;
    .avatar-group{
      margin-bottom: 10px;
    }
    p{
      margin: 10px 0;
    }
  }
 .panel-item{
    .cont{
      flex-wrap: wrap;
      margin-top: 20px;
      .item{
        margin:20px;
      }
    }
  }
}
@media screen and (max-width: 575px) {
  .agent-info{
    p{
      font-size: 14px;
      margin:5px 0;
    }
    .avatar-group{
      flex-direction: column;
      .name{
        display: block;
        font-size: 18px;
        line-height: 1.2;
      }
    }
  }
  .panel-item{
    h2{
      height: 30px;
      line-height: 30px;
      &::before{
        height:20px;
      }
      span{
        font-size: 18px;
      }
    }
    .cont .item {
      margin:5px;
      p{
        font-size: 14px;
      }
      .show {
        margin-top: 10px;
        line-height: 1.2;
        .icon{
          font-size: 30px;
        }
      }
    }
  }
}

</style>
