
import { defineComponent, reactive, toRefs, ref } from 'vue'
import { isVaildPass, isVaildTel } from '@/utils/validate'
export interface FormData {
  id: string
  agent_name: string
  mobile: string
  password: string
}
export default defineComponent({
  emits: ['validSuccess', 'handleReset'],
  props: {
    initData: {
      type: Object,
      default: () => {
        return {
          id: '',
          agent_name: '',
          mobile: '',
          password: ''
        }
      }
    }
  },
  setup(props, ctx) {
    const editFrom = ref(null)
    const staticMethods = {
      validatePass: (rule: any, value: string, callback: Function) => {
        if (!isVaildPass(value)) {
          callback(new Error('密码需要英文+数字，不低于8位'))
        } else {
          callback()
        }
      },
      validateTel: (rule: any, value: string, callback: Function) => {
        if (!isVaildTel(value)) {
          callback(new Error('请输入正确手机号'))
        } else {
          callback()
        }
      }
    }
    const { id, agent_name, mobile, password } = toRefs(props.initData)
    const editInfo = reactive({
      formData: {
        id,
        agent_name,
        mobile,
        password
      },
      submitLoading: false,
      formRules: {
        agent_name: [
          { required: true, message: '请输入销售名', trigger: 'blur' }
        ],
        mobile: [
          { required: true, message: '请输入手机号', trigger: 'blur' },
          { validator: staticMethods.validateTel, trigger: 'blur' }
        ],
        password: [
          { required: true, message: '请输入密码', trigger: 'blur' },
          { validator: staticMethods.validatePass, trigger: 'blur' }
        ]
      }
    })
    const formMethod = reactive({
      submitForm: () => {
        (editFrom.value as any).validate(async(valid: boolean) => {
          const { formData } = editInfo
          if (valid) {
            editInfo.submitLoading = true
            ctx.emit('validSuccess', formData, function() {
              editInfo.submitLoading = false
            })
          } else {
            console.log('error submit!!')
            return false
          }
        })
      },
      resetForm: () => {
        ctx.emit('handleReset')
      }
    })
    return { ...toRefs(editInfo), editFrom, ...toRefs(formMethod) }
  }
})
