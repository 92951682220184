
import { defineComponent, reactive, toRefs, onBeforeMount, ref, nextTick } from 'vue'
import DistributorTable from '../spread/components/DistributorTable.vue'
import DivideTable from '../spread/components/Table.vue'
import requestMiddle, { RequestInter } from '@/utils/request'
import { requestSecondInfo, requestSeconEdit } from '@/apis/second'
import { CountTo } from 'vue3-count-to'
import { ElMessage, ElMessageBox } from 'element-plus'
import { useRoute } from 'vue-router'
import { IndexMessage, AgentType } from '@/model/orderModel'
import InfoEditForm, { FormData } from './components/InfoEditForm.vue'
export default defineComponent({
  components: {
    DistributorTable,
    DivideTable,
    CountTo,
    InfoEditForm
  },
  setup() {
    const route = useRoute()
    const avarImg = ref(null)
    const currentType = ref('distributor-table')
    const editInfo = reactive({
      formData: {
        id: '',
        agent_name: '',
        mobile: '',
        password: ''
      },
      editVisible: true
    })
    const state = reactive({
      linkInfo: [],
      achieve: {},
      info: {},
      isLoading: true,
      isSecondary: true,
      popCanShow: true,
      toOrder: '/order',
      requestInfo: async() => {
        const option = { agent_id: editInfo.formData.id }
        const data = await requestMiddle({
          requestMethodName: requestSecondInfo,
          option
        } as RequestInter)
        try {
          const { achievement_data, info, share_list } = data
          state.achieve = achievement_data
          state.info = info
          if (info.agent_type === AgentType.DIVIDER) { // 分成代理
            currentType.value = 'divide-table'
          }
          state.linkInfo = share_list.map((item: IndexMessage<string | number>, index: number) => {
            item.serial = index + 1
            return item
          })
          editInfo.formData.id = info.id
          const { agent_name, mobile } = info
          editInfo.formData.agent_name = agent_name
          editInfo.formData.mobile = mobile
        } catch (error) {
          console.log(error)
        }
        state.isLoading = false
      }
    })

    const formMethod = reactive({
      validSuccess: async(option: FormData, cb: () => void) => {
        await requestSeconEdit(option).then((res) => {
          if (res?.code === 200) {
            state.popCanShow = false
            nextTick(() => {
              state.popCanShow = true
            })
            ElMessage.success('修改成功')
            state.requestInfo()
          } else {
            ElMessage.error(res?.msg)
          }
        })
        cb()
      },
      handleReset: () => {
        state.popCanShow = false
        nextTick(() => {
          state.popCanShow = true
        })
      }
    })

    const freeze = reactive({
      confText: '二级代理冻结后，推广链接失效，后续订单会统计到主账户下。确认冻结吗？',
      handleFreeze: () => {
        ElMessageBox.confirm(freeze.confText, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          console.log(2)
        }).catch(() => {
          console.log('放弃修改')
        })
      }
    })

    onBeforeMount(() => {
      if (route.name === 'Basicinfo') { // 一级代理系统
        const id = route.params.id as string
        state.isSecondary = false
        editInfo.formData.id = id
        state.toOrder = `/order/secondaryList/${id}`
      }
      state.requestInfo()
    })
    return { ...toRefs(state), ...toRefs(editInfo), ...toRefs(formMethod), avarImg, currentType, ...toRefs(freeze) }
  }
})
