import { RootObject } from '@/model/rootObject'
import { IndexMessage } from '@/model/orderModel'
import https from '@/utils/https'
import { ContentType, Method, RequestParams } from 'axios-mapper'
export interface SearchParams{
  size?: number
  page?: number
  search_key?: string
  start_time?: number
  end_time?: number
  export? : number
}
export interface AddAgent{
  mobile: number|string
  password: string
  agent_name: string
}
// 二级代理列表
export const requestSecondList = (option: SearchParams) => {
  return https().request<RootObject<IndexMessage<IndexMessage<string | number>>>>('/agent/second/list', Method.POST, option, ContentType.json)
}

// 新增二级代理
export const requestAddSecond = (option: AddAgent) => {
  return https().request<RootObject<IndexMessage<IndexMessage<string | number>>>>('/agent/second/add', Method.POST, option, ContentType.json)
}

// 批量新增二级代理
export const requestImportSecond = (option: RequestParams) => {
  return https().request<RootObject<IndexMessage<IndexMessage<string | number>>>>('/agent/second/import', Method.POST, option, ContentType.json)
}

// 二级代理详情
export const requestSecondInfo = (option: RequestParams) => {
  return https().request<RootObject<IndexMessage<IndexMessage<string | number>>>>('/agent/second/info', Method.POST, option, ContentType.json)
}

// 二级代理信息编辑
export const requestSeconEdit = (option: RequestParams) => {
  return https().request<RootObject<IndexMessage<IndexMessage<string | number>>>>('/agent/second/edit', Method.POST, option, ContentType.json)
}
