
import { defineComponent, onBeforeMount, watch, reactive, toRefs } from 'vue'
import { requestStatus } from '@/apis/spread'
import { SpreadLink } from '@/model/spreadOrder'
import { ElMessage, ElMessageBox } from 'element-plus'
// import requestMiddle, { RequestInter } from '@/utils/request'
interface LinkData{
    handleSwitch: (row: Row) => void
    handleEdit: (row: Row) => void
    mergeArray: number[]
    currentData: Array<SpreadLink<SpreadLink<string | number>>>
    pos: number
}
interface Row{
  id: Number
  status: Boolean
}
export default defineComponent({
  props: {
    data: {
      type: Array,
      default() {
        return []
      }
    }
  },
  emits: ['reFresh'],
  setup(props: any) {
    const data: LinkData = reactive({
      mergeArray: [],
      currentData: [],
      pos: 0,
      handleEdit(row: Row) {
        (this as any).$router.push({ path: '/spread/edit', query: { id: row && row.id } })
      },
      handleSwitch: async(row: Row) => {
        // 上架状态变下架就弹出提示
        row?.status && ElMessageBox.alert('链接已下架!')
        requestStatus({
          id: row && row.id, status: row && !row.status
        }).then((res) => {
          if (res?.code === 200) {
            row && (row.status = !row.status)
          } else {
            ElMessage.warning(res?.msg)
          }
        })
      },
      mergeSameRow({ rowIndex, columnIndex }: {row: any, column: any, rowIndex: number, columnIndex: number}) {
        if (columnIndex === 1 || columnIndex === 3 || columnIndex === 4) {
          const _row: number = data.mergeArray[rowIndex]
          const _col: number = _row > 0 ? 1 : 0
          return {
            rowspan: _row,
            colspan: _col
          }
        }
      }
    })

    watch(() => props.data, (now) => {
      data.currentData = now
      const list: Array<SpreadLink<SpreadLink<string | number>>> = props.data
      for (let i = 0; i < list.length; i++) {
        if (i === 0) {
          data.mergeArray.push(1)
          data.pos = 0
        } else {
          // 判断当前元素与上一个元素是否相同
          // 根据相同id进行合并,根据需要可进行修改
          if (list[i].share_link === list[i - 1].share_link) {
            data.mergeArray[data.pos] += 1
            data.mergeArray.push(0)
          } else {
            data.mergeArray.push(1)
            data.pos = i
          }
        }
      }
    })
    onBeforeMount(() => {
      console.log(props.data)
    })
    return { ...toRefs(data) }
  }
})
